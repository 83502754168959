export const initSetClassSubHeader = async () => {
  const isVisible = document.querySelector('.section-visible');

  if (isVisible) {
    const pageHeader = document.querySelector('.page-header');
    const superHeader = document.querySelector('.super-header');
    const sectionElement = document.querySelector('[class*="section"]');

    pageHeader.classList.add('hasSubheader');

    const sectionName = sectionElement?.classList[1];
    pageHeader?.classList.add(sectionName);
    superHeader?.classList.add(sectionName);
  }
};
